@font-face {
    font-family: 'Objectivity';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("Objectivity-Regular.otf") format("opentype")
}
@font-face {
    font-family: 'Objectivity';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("Objectivity-Regular.woff2") format("woff2")
}
@font-face {
    font-family: 'Objectivity';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("Objectivity-Bold.otf") format("opentype")
}
@font-face {
    font-family: 'Objectivity';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("Objectivity-Bold.woff2") format("woff2")
}

